import React, {FunctionComponent} from "react";
import {IconButton} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {RouterPaths} from "../../../../../navigation";
import {useJobContext} from "../../../JobPortalLayoutPage";

interface IRedirectProps {
    tabId?: number;
    sectionId?: number;
    subSectionId?: number;
    itemId?: number;
}

export interface IRedirectButtonProps extends IRedirectProps {
    iconName?: string;
    disabled?: boolean;
}

export const RedirectButton : FunctionComponent<IRedirectButtonProps> = ({ tabId, sectionId, subSectionId, itemId, ...props }: IRedirectButtonProps) => {
    const {navigateToTableRow} = useJobRedirects();
    
    const handleOnClick = () => {
        navigateToTableRow({ tabId: tabId, sectionId: sectionId, subSectionId: subSectionId, itemId: itemId});
    }
    
    return (
        <>
            <IconButton 
                disabled={props.disabled}
                onClick={handleOnClick}
                iconProps={{iconName: props.iconName ?? 'RedEye'}}/>
        </>
    )
}

type NavigationWindowFeatures = {
    toolbar: boolean,
    location: boolean,
    menubar: boolean,
    width?: number,
    height?: number
}

function useJobRedirects() {
    const {jobGuid} = useJobContext();
    const navigate = useNavigate();
    
    const navigateToTableRow = ({tabId, sectionId, subSectionId, itemId}: IRedirectProps) => {
        if (tabId) {
            const sectionPath = subSectionId ? `${subSectionId}/${sectionId}` : sectionId;
            navigate(`${RouterPaths.job.tab(jobGuid, tabId)}${sectionPath ? '?sectionId=' + sectionPath : ''}${itemId ? '#' + itemId : ''}`)
        }
    }
    
    const navigateToJobExternal = (path: string, target: '_blank' | string = '', features?: NavigationWindowFeatures) => {
        if (path) {
            const _features = {
                toolbar: 0,
                location: 0,
                menubar: 0,
                ...(features ?? {})
            }
            
            window.open(`${RouterPaths.job.external.root(jobGuid)}/${path}`, target, Object.keys(_features).reduce((acc: string[], k) => {
                acc.push(`${k}=${_features[k as keyof NavigationWindowFeatures]}`)
                return acc;
            }, []).join(','))
        }
    }
    
    return {navigateToTableRow, navigateToJobExternal}
}

export { useJobRedirects }