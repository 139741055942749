import {
	IAdministrationControlProps,
	IAuditReportOptions, IDialogConfig,
	IInvoiceInfo,
	IPublishQueriesResult,
	PublishResponseType
} from "../interfaces";
import { useJobContext } from "../../../../../JobPortalLayoutPage";
import { useSectionContext } from "../../../../Section";
import { PortalRole, useTenantInfo, useWorkContext } from "../../../../../../../providers";
import { usePublishAudit } from "../hooks";
import { DialogMessage, Modal } from "../../../../../../../components";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { DialogType, mergeStyleSets, PrimaryButton, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { AuditReportOptionsForm } from "./AuditReportOptionsForm";

interface IAdministrationDispatchButtonProps extends IAdministrationControlProps {

}

export const AdministrationDispatchButton: FunctionComponent<IAdministrationDispatchButtonProps> = ({ disabled }: IAdministrationDispatchButtonProps) => {
	const {formatMessage} = useIntl();
	const theme = useTheme();

	const DefaultDialogContentProps = {
		theme: theme.schemes?.default,
		type: DialogType.normal,
		closeButtonAriaLabel: 'Close',
	};

	const {isBlackLabel, isAuditPortal} = useTenantInfo()
	
	const {jobId} = useJobContext();
	const {section} = useSectionContext();
	const {isInRoleOrSuperAdministrator, workContext} = useWorkContext();
	
	const {publish, isPublishing} = usePublishAudit();

	const [isWhiteLabel] = useState<boolean>(isAuditPortal && !isBlackLabel);
	
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const [showConfirmDialog, {toggle: toggleConfirmDialog, setFalse: setShowConfirmDialogFalse}] = useBoolean(false);
	const [confirmDialogConfig, setConfirmDialogConfig] = useState<IDialogConfig | null>(null);
	const [showDialog, {toggle: toggleDialog}] = useBoolean(false);
	const [showAuditReportOptionsModal, {toggle: toggleAuditReportOptionsModal}] = useBoolean(false);
	
	const [auditReportOptions, setAuditReportOptions] = useState<IAuditReportOptions | undefined | null>(null);

	const [isDraftAuditReportRequired, setIsDraftAuditReportRequired] = useState<boolean | null>(null);
	const [invoiceInfo, setInvoiceInfo] = useState<IInvoiceInfo | undefined | null>(null);
	const [isInvoiceEnabled, setIsInvoiceEnabled] = useState<boolean>(false);
	
	const [dispatchResultWarnings, setDispatchResultWarnings] = useState<string[]>([])
	const [dispatchResult, setDispatchResult] = useState<IPublishQueriesResult | null>(null);

	const isButtonDisabled = useMemo(() => {
		return !!disabled || !!workContext?.job?.isDispatched || !isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.PowerUser)
	}, [disabled, workContext?.job?.isDispatched])
	
	const startProcess = () => {
		setDispatchResultWarnings([])
		setIsProcessing(true);
	}
	
	const endProcess = () => {
		setIsDraftAuditReportRequired(null);
		setAuditReportOptions(null);
		setInvoiceInfo(null);
		setConfirmDialogConfig(null);
		// setOverrideDialogConfig(null);
		setIsProcessing(false);
		setDispatchResult(null);
		setDispatchResultWarnings([]);
		console.debug("[DISPATCH::PROCESS_ENDED]");
	}

	const handleOnClick = useCallback(() => {
		console.debug("[DISPATCH::PROCESS_STARTED]");
		startProcess();
	}, [])
	
	const onConfirmPaymentReceivedClick = useCallback(() => {
		console.debug("[DIALOG::CONFRIM_PAYMENT_RECEIVEVD]");
		closeConfirmDialog();
		toggleAuditReportOptionsModal()
	}, []);

	const onCancelPaymentReceivedClick = useCallback(() => {
		console.debug("[DIALOG::CANCEL_PAYMENT_RECEIVEVD]");
		closeConfirmDialog();
		endProcess();
	}, []);

	const onConfirmOverrideWarningsClick = useCallback(() => {
		console.debug("[DIALOG::CONFRIM_OVERRIDE_MESSAGES]");
		setDispatchResult(null);
		publish({
			jobId: jobId,
			sendEmail: section.sectionAnswer?.sendEmail ?? false,
			ignoreChecks: true
		}, {
			onSuccess: (result: IPublishQueriesResult) => {
				setDispatchResult(result);
			},
			onError: () => {
				endProcess()
			}
		})
		closeConfirmDialog();
	}, []);

	const onCancelOverrideWarningsClick = useCallback(() => {
		console.debug("[DIALOG::CONFRIM_OVERRIDE_MESSAGES]");
		closeConfirmDialog();
		endProcess();
	}, []);
	
	const onCancelWarningsClick = useCallback(() => {
		closeConfirmDialog();
	}, [])
	
	const closeConfirmDialog = useCallback(() => {
		setShowConfirmDialogFalse();
		setConfirmDialogConfig(null);
		endProcess()
	}, [])
	
	useEffect(() => {
		if (isProcessing) {
			publish({
				jobId: jobId,
				sendEmail: section.sectionAnswer?.sendEmail ?? false,
				ignoreChecks: false
			}, {
				onSuccess: (result: IPublishQueriesResult) => {
					setDispatchResult(result);
				},
				onError: () => {
					endProcess()
				}
			})
		}
	}, [isProcessing]);

	useEffect(() => {
		if (!dispatchResult) {
			return;
		}
		
		setIsDraftAuditReportRequired(prev => dispatchResult.isDraftAuditReportRequired);
		setInvoiceInfo(dispatchResult.invoiceInfo);
		setIsInvoiceEnabled(isBlackLabel && !!dispatchResult.invoiceInfo?.invoiceEnabled);
		setAuditReportOptions(dispatchResult.auditReportOptions);
		
		if (dispatchResult.type === PublishResponseType.Ok) {
			console.debug("[DISPATCH::RECEIVED::FLOW_1]", dispatchResult);
			// TODO: the first if viewmodel_administration.js:455
		} else if (dispatchResult.type === PublishResponseType.AuditReport && (isWhiteLabel || !!dispatchResult.invoiceInfo?.isFeeCalculated)) {
			console.debug("[DISPATCH::RECEIVED::FLOW_2]", dispatchResult);
			if (dispatchResult.isAuditReportWitheld) {
				setConfirmDialogConfig(prev => ({
					contentProps: { 
						...DefaultDialogContentProps,
						title: formatMessage({id: 'confirm'})
					},
					showOk: true,
					okText: formatMessage({id: 'confirmPaymentReceived'}),
					onClick: onConfirmPaymentReceivedClick,
					onCancel: onCancelPaymentReceivedClick,
					content: formatMessage({id: 'confirmPaymentReceivedMessage'})
				}));
				toggleConfirmDialog();
			} else {
				toggleAuditReportOptionsModal();
			}
		} else {
			console.debug("[DISPATCH::RECEIVED::FLOW_3]", dispatchResult);
			if (dispatchResult.showIgnoreCheck && isInRoleOrSuperAdministrator(PortalRole.Administrator) && (isWhiteLabel || !!dispatchResult.invoiceInfo?.isFeeCalculated)) {

				const messageList = [...(dispatchResult.messages ?? [])];
				
				setConfirmDialogConfig(prev => ({
					contentProps: {
						...DefaultDialogContentProps,
						title: formatMessage({id: 'warning'})
					},
					showOk: true,
					okText: formatMessage({id: 'continue'}),
					onClick: onConfirmOverrideWarningsClick,
					onCancel: onCancelOverrideWarningsClick,
					content: (
						<Stack>
							<Stack.Item>
								{messageList.length && messageList.map(w => <li>{w}</li>)}
							</Stack.Item>
							<Stack.Item>
								{formatMessage({id: 'dispatchOverrideMessage'})}
							</Stack.Item>
						</Stack>
					)
				}));
				toggleConfirmDialog();
			} else {
				const messageList = [
					...(dispatchResult.messages ?? []),
					...(isBlackLabel && !dispatchResult.invoiceInfo?.isFeeCalculated && !!dispatchResult.invoiceInfo?.hasErrorMessage1 ? [dispatchResult.invoiceInfo!.errorMessage1] : [])]
				
				setConfirmDialogConfig(prev => ({
					contentProps: {
						...DefaultDialogContentProps,
						title: formatMessage({id: 'publishDispatchAuditQueriesWarning'})
					},
					showOk: false,
					okText: '',
					onClick: () => {},
					onCancel: onCancelWarningsClick,
					content: (
						<Stack>
							<Stack.Item>
								{messageList.length && messageList.map(w => <li>{w}</li>)}
							</Stack.Item>
						</Stack>
					)
				}));
				toggleConfirmDialog();
			}
		}
		
	}, [dispatchResult, isWhiteLabel, isBlackLabel]);
	
	const onAuditReportOptionsClose = () => {
		toggleAuditReportOptionsModal();
		endProcess();
	}

	const classNames = mergeStyleSets({
		dialog: {
			'.ms-Dialog-main': {
				minWidth: '500px',
				backgroundColor: 'red',

				'.ms-Dialog-inner': {
					marginLeft: 16
				}
			}
		}
	})

	const dialogContentProps = {
		...DefaultDialogContentProps,
		title: formatMessage({id: 'publishDispatchAuditQueriesWarning'}),
	};

	return (
		<>
			<Stack>
				<Stack.Item>
					<PrimaryButton onClick={handleOnClick} disabled={isButtonDisabled || isProcessing}>
						{isProcessing
							? <Spinner size={SpinnerSize.small}/>
							: formatMessage({id: 'dispatchAuditQueries'})
						}
					</PrimaryButton>
				</Stack.Item>
			</Stack>

			{/*<Stack className={classNames.dialog}>*/}
			{/*	<DialogMessage onClick={toggleDialog} onDismis={toggleDialog}*/}
			{/*	               dialogContentProps={dialogContentProps}*/}
			{/*	               hidden={!showDialog}*/}
			{/*	               showOk={false}*/}
			{/*	               cancelText={formatMessage({id: 'close'})}>*/}
			{/*		{dispatchResultWarnings.length && dispatchResultWarnings.map(w => <li>{w}</li>)}*/}
			{/*	</DialogMessage>*/}
			{/*</Stack>*/}

			{confirmDialogConfig && <Stack className={classNames.dialog}>
				<DialogMessage onClick={confirmDialogConfig.onClick} onDismis={confirmDialogConfig.onCancel}
				               dialogContentProps={confirmDialogConfig.contentProps}
				               hidden={!showConfirmDialog}
				               showOk={confirmDialogConfig.showOk}
				               okText={confirmDialogConfig.okText}
				               cancelText={formatMessage({id: 'close'})}>
					{confirmDialogConfig.content}
				</DialogMessage>
			</Stack>}

			{auditReportOptions && <Modal isOpen={showAuditReportOptionsModal}
                                          title={formatMessage({ id: 'auditReportOptions' })}
                                          onDismiss={onAuditReportOptionsClose}
            >
                <AuditReportOptionsForm options={auditReportOptions}
                                        invoiceInfo={invoiceInfo}
                                        isDraftAuditReportRequired={isDraftAuditReportRequired!}
                                        invoiceEnabled={isInvoiceEnabled}
                                        onClose={onAuditReportOptionsClose} />
            </Modal>}
		</>
	)
}