import { useContext } from 'react';
import { Context } from '../../../../../../../utils';
import { useMutation, useQuery } from "react-query";
import axios from 'axios';

type IsPaymentReceivedResult = {
    isPaymentReceived: boolean;
    timestamp: number;
};

function useGetIsPaymentReceived(jobId: number) {
    const context = useContext(Context);

    const {
        data: data,
        isLoading,
        refetch,
        isRefetching, 
    } = useQuery(
        ['isPaymentsReceived', jobId],
        () => axios.get<IsPaymentReceivedResult>(`${context?.config?.queriesUrl}/api/1.0/queries/isPaymentReceived/${jobId}`), {
            enabled: false,
            staleTime: 100
        }
    );

    return {
        isPaymentRecievedResult: data?.data || null,
        isLoading,
        refetch,
        isRefetching,
    };
}

export { useGetIsPaymentReceived };
