import {createContext, FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {DelayedRender, Stack} from '@fluentui/react';
import {useGetTabById} from 'pages/JobPortal/hooks';
import {Section} from 'pages/JobPortal/components';
import {useParams, useSearchParams} from 'react-router-dom';
import {Loading} from "../../components";
import {useJobContext} from "./JobPortalLayoutPage";
import {ISectionInfo} from "./interfaces";
import {useScrollTo} from 'hooks';
import {useWorkContext} from "../../providers";


export interface ITabContext {
    tabId: number;
    openSection: (reference: string) => void;
    closeSection: (reference: string) => void;
    closeSectionById: (id: number) => void;
    disableSection: (reference: string, disabled: boolean) => void;
    refreshSection: (reference: string) => void;
    refreshSectionById: (id: number) => void;
    updateSectionAnswerById: (id: number, isPositive: boolean | null) => void;
    sections?: ISectionInfo[],
    isTabEnabled: boolean;
}

export const TabContext = createContext<ITabContext | null>(null);


export const JobPortalPage: FunctionComponent = () => {
    const {tabId} = useParams();
    const {job, suggestedTestingInfo, jobTabs} = useJobContext();
    const {isJobPortalEnabled} = useWorkContext();
    const {tabData, isTabDataLoading, refetch} = useGetTabById({
        templateId: job.templateId,
        tabId: Number(tabId),
        jobId: job.id,
    });
    const [sections, setSections] = useState<ISectionInfo[]>();

    const [searchParams] = useSearchParams();
    const sectionPrefix: string = 'job-portal-section-';
    const {scrollTo} = useScrollTo(sectionPrefix, 17 * 100);

    const sectionRefs = useRef<any>([]);

    const [isTabEnabled, setIsTabEnabled] = useState<boolean>(true);
    useEffect(() => {
        const tab = jobTabs.find(t => t.id === +tabId!);
        setIsTabEnabled(isJobPortalEnabled || !tab?.isFreezedEnabled)
    }, [isJobPortalEnabled, tabId, jobTabs]);

    const handleOnOpenSection = (reference: string) => {
        const necessarySection = sections?.find(x => x.reference === reference);
        if (necessarySection)
            changeSection({...necessarySection, openOnStartUp: true})
    };

    const handleOnCloseSection = (reference: string) => {
        const necessarySection = sections?.find(x => x.reference === reference);
        if (necessarySection)
            changeSection({...necessarySection, openOnStartUp: false})
    };

    const handleOnCloseSectionById = (id: number) => {
        const necessarySectionRef = sectionRefs.current.find((x: any) => x.id === +id);
        if (necessarySectionRef && necessarySectionRef.isOpened()) {
            necessarySectionRef.close()
        }
    }

    const handleOnDisableSection = (reference: string, disabled: boolean) => {
        const necessarySection = sections?.find(x => x.reference === reference);
        if (necessarySection)
            changeSection({...necessarySection, disabled: disabled})
    };

    const handleOnRefreshSection = (reference: string) => {
        const sectionRef = sectionRefs.current.find((x: any) => x.reference === reference);
        console.debug("[RFE]", reference, sectionRef);
        if (sectionRef?.isOpened?.()) {
            sectionRef.refresh?.();
        }
    }

    const handleOnRefreshSectionById = (id: number) => {
        const sectionRef = sectionRefs.current.find((x: any) => x.id === id);
        if (sectionRef?.isOpened?.()) {
            console.debug("[TAB_CONTEXT::REFRESH_SECTION_BY_ID]", id, sectionRef);
            sectionRef.refresh?.();
        }
    }

    const handleOnUpdateSectionAnswerById = (id: number, isPositive: boolean | null) => {
        const necessarySectionRef = sectionRefs.current.find((x: any) => x.id === +id);
        if (necessarySectionRef) {
            necessarySectionRef.updateSectionAnswer(isPositive)
        }
    }

    const changeSection = (section: ISectionInfo) => {
        setSections(prevState => prevState?.map(x => x.id === section.id ? section : x));
    };

    useEffect(() => {
        const openedSectionPath = searchParams.get('sectionId') ?? '';
        scrollTo(openedSectionPath);

        // const [ openendSubSectionId, openedSectionId ] = openedSectionPath.split('/');
        const openedSectionIds = openedSectionPath.split('/').filter(x => !!x).map(x => +x);

        const updatedSections = (tabData?.data ?? []).reduce((acc: any[], i: any) => {
            acc.push({
                ...i,
                openOnStartUp: openedSectionIds.includes(i.id) || i.openOnStartUp || sections?.find(x => x.id == i.id)?.openOnStartUp,
                children: [...i.children.map((c: any) => ({
                    ...c,
                    openOnStartUp: openedSectionIds.includes(c.id) || c.openOnStartUp
                }))]
            })
            return acc;
        }, [])

        setSections(updatedSections);

        const suggestedTestingSection = (tabData?.data ?? []).find(x => x.reference === 'Suggested Testing');
        if (suggestedTestingInfo && suggestedTestingSection) {
            suggestedTestingInfo.setIsApproved(suggestedTestingSection.sectionAnswer?.managerApproval ?? false)
        }

        sectionRefs.current = sectionRefs.current.slice(0, tabData?.data?.length ?? 0);

    }, [tabData?.data, searchParams, scrollTo]);

    useEffect(() => {
        refetch()
    }, [tabId])

    if (isTabDataLoading) {
        return (<Stack horizontalAlign='center' verticalAlign='center' styles={{root: {height: '85vh'}}}>
            <Loading/>
        </Stack>)
    }

    const contextValues: ITabContext = {
        tabId: Number(tabId),
        closeSection: handleOnCloseSection,
        closeSectionById: handleOnCloseSectionById,
        openSection: handleOnOpenSection,
        disableSection: handleOnDisableSection,
        refreshSection: handleOnRefreshSection,
        refreshSectionById: handleOnRefreshSectionById,
        updateSectionAnswerById: handleOnUpdateSectionAnswerById,
        sections: sections,
        isTabEnabled: isTabEnabled
    };

    return (
        <TabContext.Provider value={contextValues}>
            <DelayedRender delay={5}>
                <Stack
                    tokens={{childrenGap: 16}}
                    styles={{
                        root: {
                            paddingBottom: 8,
                            height: '100%',
                            overflowY: 'auto',
                        },
                    }}>
                    {sections?.map((section, index) => (
                        <Stack
                            key={`${sectionPrefix}${section.id}_key`}
                            id={`${sectionPrefix}${section.id}`}
                            styles={{
                                root: {
                                    ':not(:first-child):empty': {
                                        marginTop: 0
                                    }
                                }
                            }}>
                            <Section
                                section={section}
                                ref={(el) => (sectionRefs.current[index!] = el)}
                                key={`${sectionPrefix}${section.id}`}
                            />
                        </Stack>
                    ))}
                </Stack>
            </DelayedRender>
        </TabContext.Provider>
    );
};

export const useTabContext = () => {
    let context = useContext(TabContext);

    if (!context)
        throw new Error('No TabContext.Provider found');

    return context;
}
