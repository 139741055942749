import { Stack, useTheme, Text, TextField, PrimaryButton, mergeStyleSets, FontWeights, Icon, AnimationStyles, Spinner } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SoftwareType, TableType } from '../../../../../../enums';
import { IClientPortalTab, useGetClientPortalTabs } from '../../hooks/useGetClientPortalTabs';
import { AttachmentDropZone, ClientPortalTabAttachment } from '../attachments/Attachment';
import { ClientPortalTabComment } from './ClientPortalTabComment';
import { RequiredDocumentsInfo } from './RequiredDocumentsInfo';
import { PortalRole, useWorkContext } from "../../../../../../providers";

interface IClientPortalTabsProps {
    job: any;
    selectedSoftwareType: SoftwareType;
    isWhiteLabel?: boolean;
}

interface IClientPortalTabProps {
    tab: IClientPortalTab;
    job: any;
    isJobDispatched: boolean;
}

interface IClientPortalTabTitleProps {
    title: string;
    isOpened: boolean;
    toggleIsOpened: () => void;
    comment?: string;
    isCommentDisabled: boolean;
    id: number;
    job: any;
    isExpandable: boolean;
}

interface IClientPortalTabContentProps {
    tabCommentId: number;
}

const ClientPortalTabContent: FunctionComponent<IClientPortalTabContentProps> = ({tabCommentId}) => {
    return <RequiredDocumentsInfo tabCommentId={tabCommentId} />;
}

const ClientPortalTabTitle: FunctionComponent<IClientPortalTabTitleProps> = ({title, isOpened, toggleIsOpened, comment, isCommentDisabled, id, job, isExpandable}) => {
    const theme = useTheme();
    const {isInRole} = useWorkContext();

    const handleOnClickTitle = () => {
        if (isExpandable) {
            toggleIsOpened();
        }
    }

    const expanderIcon = isOpened ? 'ChevronUp' : 'ChevronDown';

    const classNames = mergeStyleSets({
        itemContent: {
            height: 50,
            paddingLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
            cursor: isExpandable ? 'pointer' : '',
            borderBottom: isOpened ? `1px solid ${theme.palette.neutralTertiaryAlt}` : ''
        },
        itemHeader: [
            {
                padding: theme.spacing.m,
            }
        ],
        itemName: [
            theme.fonts.medium,
            {
                fontWeight: FontWeights.regular,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                'span': { color: theme.schemes?.default?.semanticColors.bodyText },
            },
        ],
        chevron: {
            alignSelf: 'center',
            fontWeight: FontWeights.bold,
            color: theme.palette.themePrimary,
            fontsize: theme.fonts.tiny.fontsize,
            flexShrink: 0,
        },
        caption: {

        },
        captionPascal: {
            textTransform: 'lowercase',
            '::first-letter': {
                textTransform: 'uppercase'
            }
        }
    });

    const [isDocumentDeleteEnabled, setIsDocumentDeleteEnabled] = useState<boolean>(!isInRole(PortalRole.CPUser) && !isCommentDisabled);

    if (!!job?.dateReceived) {
        return (
            <Stack
                tokens={{ childrenGap: 16 }}
                horizontal
                className={classNames.itemContent}
                horizontalAlign={'space-between'}
                verticalAlign={'center'}>
                <Stack
                    verticalAlign={'center'}
                    className={classNames.itemHeader}
                    grow={3}
                    horizontal
                    tokens={{ childrenGap: 16 }}
                    onClick={handleOnClickTitle}>
                    {isExpandable && <Icon iconName={expanderIcon} className={classNames.chevron} />}
                    <Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 8 }} className={classNames.itemName}>
                        <Text className={classNames.caption}>{title}</Text>
                    </Stack>
                </Stack>
                <Stack verticalAlign={'center'} horizontalAlign={'end'} horizontal>
                    <ClientPortalTabComment comment={comment} disabled={isCommentDisabled} itemId={id} jobId={job.id} />
                    <ClientPortalTabAttachment
                        jobId={job.id}
                        fundId={job.fund?.id}
                        clientId={job.client?.id}
                        year={job.year}
                        itemId={id}
                        hasAttachments={false}
                        isDeletingEnabled={isDocumentDeleteEnabled}
                        tableType={TableType.JobTabAttachment}
                    />
                </Stack>
            </Stack>
        );
    }

    return (
        <AttachmentDropZone jobId={job.id} fundId={job.fund?.id} clientId={job.client?.id}
         itemId={id} tableType={TableType.JobTabAttachment}>
            <Stack tokens={{ childrenGap: 16 }}
            horizontal
            className={classNames.itemContent}
            horizontalAlign={"space-between"}
            verticalAlign={"center"}>
            <Stack verticalAlign={'center'} className={classNames.itemHeader} grow={3} horizontal
                tokens={{ childrenGap: 16 }}
                onClick={handleOnClickTitle}>
                {isExpandable && <Icon iconName={expanderIcon} className={classNames.chevron} />}
                <Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 8 }}
                    className={classNames.itemName}>
                    <Text className={classNames.caption}>{title}</Text>
                </Stack>
            </Stack>
            <Stack verticalAlign={'center'} horizontalAlign={'end'} horizontal>
                <ClientPortalTabComment comment={comment} disabled={isCommentDisabled} itemId={id} jobId={job.id} />
                <ClientPortalTabAttachment jobId={job.id} fundId={job.fund?.id} clientId={job.client?.id}
                    year={job.year} itemId={id} hasAttachments={false} isDeletingEnabled={isDocumentDeleteEnabled} tableType={TableType.JobTabAttachment} />
            </Stack>
        </Stack>
        </AttachmentDropZone>);
}

const ClientPortalTab: FunctionComponent<IClientPortalTabProps> = ({ tab, job, isJobDispatched }) => {
    const theme = useTheme();
    const [isOpened, { toggle: toggleIsOpened}] = useBoolean(false);


    const classNames = mergeStyleSets({
        itemCell: [
            {
                margin: 4,
                boxShadow: theme.effects.elevation4,
                background: theme.schemes?.default?.palette.white,
                borderRadius: theme.effects.roundedCorner2,
                selectors: {
                    '&:hover': (isOpened) ? {} : { background: 'rgba(184, 184, 184, 0.4)' },
                },
            },
        ],
    });

    return (<Stack styles={{ root: { ...AnimationStyles.fadeIn200 } }}
        className={classNames.itemCell}>
        <ClientPortalTabTitle isExpandable={tab.hideInTabs} isOpened={isOpened}
            toggleIsOpened={toggleIsOpened} title={tab.description} comment={tab.comment} id={tab.id} isCommentDisabled={isJobDispatched} job={job} />
        {isOpened && (
            <Stack tokens={{ childrenGap: 16 }}
                styles={{
                    root: {
                        padding: 16,
                        background: theme.palette.white,
                        ...AnimationStyles.fadeIn200
                    }
                }}>
                <ClientPortalTabContent tabCommentId={tab.id}/>
            </Stack>)}
    </Stack>);
}

const regulatoryDocumentsTabName = 'Regulatory Documents';

export const ClientPortalTabs: FunctionComponent<IClientPortalTabsProps> = ({ job, selectedSoftwareType, isWhiteLabel }) => {
    const { formatMessage, formatDate } = useIntl();
    const theme = useTheme();

    const { response, isLoading } = useGetClientPortalTabs(job?.id);
    const [displayedTabs, setDisplayedTabs] = useState<IClientPortalTab[] | undefined>();

    useEffect(() => {
        const needQuestions = job.clientQuestionsState === "NeedAnswers" && !isWhiteLabel && job.iicQuestionsEnabled;

        setDisplayedTabs(response?.filter(x => needQuestions ? x.description === regulatoryDocumentsTabName :
            !x.hideInTabs || selectedSoftwareType === SoftwareType.BGL360 || selectedSoftwareType === SoftwareType.Nothing));
    }, [response, selectedSoftwareType, job, isWhiteLabel]);

    return (
        <>
            {isLoading && response ? <Spinner /> : displayedTabs?.map(x => <ClientPortalTab key={x.id} tab={x} job={job} isJobDispatched={!!job?.dateReceived}/>)}
        </>
    );
}