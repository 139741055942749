import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import {UploadAttachment} from "../../../UploadAttachment";
import {TableType} from "../../../../../../enums";
import {useBoolean} from "@fluentui/react-hooks";
import {useResetDispatchAudit, useResolvePostDispatch} from "./hooks";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {CheckboxWorkDoneAnswer} from "../../answers";
import {useUpdateItemAnswer} from "../../../../hooks/answers";
import {ColumnInfo} from "../../../../enums";
import {useNotifications} from "../../../../../../components/notifications";
import {IAdministrationControlProps} from "./interfaces";
import {PortalRole, useWorkContext} from "../../../../../../providers";
import {PortalRoleGuard} from "../../../../../../guards";
import {DialogMessage} from "../../../../../../components";


interface IAdministrationCheckboxProps extends IAdministrationControlProps {
    itemId: number;
    label: string;
    checked: boolean;
    onChange?: (newValue: boolean) => void;
}

export const AdministrationAnswerCheckbox: FunctionComponent<IAdministrationCheckboxProps> = ({
                                                                                                  itemId,
                                                                                                  label,
                                                                                                  checked,
                                                                                                  disabled,
                                                                                                  onChange
                                                                                              }: IAdministrationCheckboxProps) => {
    const {jobId} = useJobContext();
    const {update, isUpdating} = useUpdateItemAnswer();

    const handleOnChange = useCallback((value: boolean) => {
        update({
            jobId: jobId,
            itemId: itemId,
            tableType: TableType.Administration,
            text: value.toString(),
            columnInfo: ColumnInfo.Checkbox
        }, {
            onSuccess: (_) => onChange?.(value)
        })
    }, [])

    return (
        <CheckboxWorkDoneAnswer
            checked={checked}
            disabled={disabled || isUpdating}
            label={label}
            onChange={handleOnChange}
        />
    )
}

interface IAdministrationUploadButtonProps extends IAdministrationControlProps {

}

export const AdministrationUploadButton: FunctionComponent<IAdministrationUploadButtonProps> = ({}: IAdministrationUploadButtonProps) => {
    const {formatMessage} = useIntl();
    const {isInRoleOrSuperAdministrator} = useWorkContext();

    const data: { itemId: number, tableType: TableType } = useMemo(() => ({
        itemId: 0,
        tableType: TableType.DispatchAudit
    }), [])

    return (
        <UploadAttachment label={formatMessage({id: 'dragAndDropOrUpload'})}
                          itemId={data.itemId}
                          disabled={(!isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.PowerUser))}
                          tableType={data.tableType}/>
    )
}

interface IAdministrationResetDispatchButtonProps extends IAdministrationControlProps {

}

export const AdministrationResetDispatchButton: FunctionComponent<IAdministrationResetDispatchButtonProps> = ({
                                                                                                                  disabled = false,
                                                                                                                  onSuccess
                                                                                                              }: IAdministrationResetDispatchButtonProps) => {
    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const {reset, isResetting} = useResetDispatchAudit();
    const {showNotification} = useNotifications();

    const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);

    const dialogContentProps: IDialogContentProps = useMemo(() => {
        return {
            type: DialogType.normal,
            title: formatMessage({id: 'confirm'}),
            closeButtonAriaLabel: 'Close',
            maxWidth: 500,
            subText: formatMessage({id: 'resetDispatchConfirmation'}),
        };
    }, [formatMessage])

    const handleOnYesClick = useCallback(() => {
        reset(jobId, {
            onSuccess: (result: any) => {
                showNotification({
                    name: formatMessage({id: 'administration'}),
                    description: result.data?.message,
                    type: result.data?.type === 1 ? MessageBarType.success : MessageBarType.warning
                })
                onSuccess?.();
            },
        })
        toggleHideDialog();
    }, [showNotification])

    return (
        <PortalRoleGuard roles={[PortalRole.Administrator]} behavior='disable' includeSuperAdministrator>
            <PrimaryButton onClick={toggleHideDialog} disabled={disabled || isResetting}>
                {isResetting
                    ? <Spinner size={SpinnerSize.small}/>
                    : formatMessage({id: 'resetDispatch'})
                }
            </PrimaryButton>
            <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps}
                    modalProps={{isBlocking: true}}>

                <DialogFooter>
                    <PrimaryButton onClick={handleOnYesClick} text={formatMessage({id: 'yes'})}/>
                    <DefaultButton onClick={toggleHideDialog} text={formatMessage({id: 'no'})}/>
                </DialogFooter>
            </Dialog>
        </PortalRoleGuard>
    )
}

interface IAdministrationPostDispatchButtonProps extends IAdministrationControlProps {
    canPostDispatch: boolean;
}

export const AdministrationPostDispatchButton: FunctionComponent<IAdministrationPostDispatchButtonProps> = ({
                                                                                                                canPostDispatch,
                                                                                                                disabled = false
                                                                                                            }: IAdministrationPostDispatchButtonProps) => {
    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const {isInRoleOrSuperAdministrator} = useWorkContext();
    const {resolve, isResolving} = useResolvePostDispatch();
    const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);
    const [resultDialog, setResultDialog] = useState<{ hidden: boolean, message?: string }>({
        hidden: true
    });

    const isButtonDisabled = useMemo(() => {
        return !!disabled || !(canPostDispatch && isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.BasicUser, PortalRole.Standard))
    }, [disabled, canPostDispatch])

    const dialogContentProps: IDialogContentProps = useMemo(() => {
        return {
            type: DialogType.normal,
            title: formatMessage({id: 'confirm'}),
            closeButtonAriaLabel: 'Close',
            subText: formatMessage({id: 'postDispatchConfirmation'}),
        };
    }, [formatMessage])

    const handleOnYesClick = useCallback(() => {
        resolve(jobId, {
            onSuccess: resp => {
                setResultDialog({
                    hidden: false,
                    message: resp.data.message,
                })
            }
        });

        toggleHideDialog();
    }, [jobId, resolve, toggleHideDialog]);

    return (
        <PortalRoleGuard roles={[PortalRole.Administrator, PortalRole.BasicUser, PortalRole.Standard]}
                         includeSuperAdministrator={true}
                         behavior='disable'>
            <PrimaryButton disabled={isButtonDisabled || isResolving} onClick={toggleHideDialog}>
                {isResolving
                    ? <Spinner size={SpinnerSize.small}/>
                    : formatMessage({id: 'postDispatchQueryResolution'})
                }
            </PrimaryButton>
            <DialogMessage onClick={() => {
                setResultDialog({hidden: true});
            }}
                           dialogContentProps={{
                               type: DialogType.normal,
                               title: formatMessage({id: 'information'}),
                               closeButtonAriaLabel: 'Close',
                               subText: resultDialog.message,
                           }}
                           showCancel={false}
                           hidden={resultDialog.hidden}
                           onDismis={() => {
                               setResultDialog({hidden: true});
                           }}/>

            <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps}
                    modalProps={{isBlocking: true}}>

                <DialogFooter>
                    <PrimaryButton onClick={handleOnYesClick} text={formatMessage({id: 'yes'})}/>
                    <DefaultButton onClick={toggleHideDialog} text={formatMessage({id: 'no'})}/>
                </DialogFooter>
            </Dialog>
        </PortalRoleGuard>
    )
}