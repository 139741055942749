import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context } from "utils";

type MutateDataType = {
    id: number;
    jobId: number;
}

function useDeleteAuditQuery() {
    const context = useContext(Context);
    const {
        mutate: deleteQuery,
        mutateAsync: deleteAsync,
        isLoading: isDeleting
    } = useMutation((data: MutateDataType) => axios.delete(`${context?.config?.queriesUrl}/api/1.0/queries/delete/${data.jobId}/${data.id}`))
    
    return {deleteQuery, deleteAsync, isDeleting};
}

export {useDeleteAuditQuery};