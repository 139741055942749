import axios from 'axios';
import {IAppTenantIdResponse} from 'interfaces/IAppTenantIdResponse';
import {useQuery} from 'react-query';
import * as localStorageService from 'services/localStorageService';

/**
 * Getting Data for tenant-id.
 * One of the main hooks, because tenant-id use in every query in headers */

function useGetTenantId(url: string) {

    const {data, isLoading} = useQuery(
        ['getTenantId', url],
        () =>
            axios.get<IAppTenantIdResponse>(`${url}&hostname=${document.domain}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }),
        {
            onSuccess: (res) => {
                 localStorageService.setData('tenantId', res.data.GlobalId);
                 localStorageService.setData('dataBaseName', res.data.DatabaseName);
                 localStorageService.setData("isWhiteLabel", res.data.IsWhiteLabel.toString());
                
                // WL
                // localStorageService.setData('tenantId', 'fb1fa6af-fb7f-42ca-aeb5-e76b0a57d93f');
                // localStorageService.setData('dataBaseName', 'evolv_wl_dev');
                // localStorageService.setData("isWhiteLabel", 'true');
                
                // CRM
                //localStorageService.setData('tenantId', 'b5f2d4ca-5b79-46fe-8725-f468ebe355b1');
                //localStorageService.setData('dataBaseName', 'evolv_crm_dev');
                //localStorageService.setData("isWhiteLabel", 'false');
            },
            useErrorBoundary: true,
            enabled: !!url,
        }
    );

    return {data, isLoading};
}

export {useGetTenantId};
