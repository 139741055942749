import {useCallback, useRef} from "react";

export function useScrollTo(idPrefix?: string, timeout?: number) {
    const scrolledRef = useRef(false);
    const hashRef = useRef('');
    const elRef = useRef<HTMLElement | null>(null);
    
    const elParentRef = useRef<HTMLElement | null>(null);
    
    const scrollTo = useCallback((id: string | number, className?: string) => {
        const idLookup = `${idPrefix}${id}`;

        if (hashRef.current !== idLookup) {
            hashRef.current = idLookup;
            scrolledRef.current = false;
        }
        
        if (!scrolledRef.current) {
            const idLookup = `${idPrefix}${id}`
            const element = document.getElementById(idLookup);

            if (element) {
                const parentEl: any = element.closest('.ms-ScrollablePane--contentContainer');
                
                setTimeout(() => {
                    if (parentEl) {

                        const cellElement = element.closest('.ms-List-cell');
                        const isLastCell = !cellElement?.nextElementSibling ?? false;
                        
                        const _cf: number = isLastCell ? 1 : 2;
                        elParentRef.current = parentEl;
                        const parentTop = element.offsetTop - (elParentRef.current?.offsetHeight ?? 0) / 1.4;
                        const scrollByValue = element.offsetTop - (elParentRef.current!.clientHeight / 2) + element.clientHeight;
                        console.debug("[ELEMENT]", element, 'OFFSET_TOP:', element.offsetTop, element.clientHeight, 'IS_LAST_CELL', isLastCell);
                        console.debug("[PARENT_TOP]", elParentRef.current, 'SCROLL_TOP:', elParentRef.current?.scrollTop, 'CLIENT_HEIGHT:', elParentRef.current?.clientHeight);
                        console.debug("[SCROLL_VVALUE_CALCULATED]", parentTop, scrollByValue);
                        console.debug("[SCROLL_WITH_PARENT]", scrollByValue - (_cf * element.clientHeight));
                        
                        elParentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        elParentRef.current?.scroll({ top: scrollByValue - (_cf * element.clientHeight) });
                    } else {
                        console.debug("[SCROLL_WITHOUT_PARENT]");
                        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    
                    if (className) {
                        if (!!elRef.current && elRef.current?.classList.contains(className)) {
                            elRef.current?.classList.remove(className);
                        }

                        element.classList.add(className);
                    }

                    scrolledRef.current = true;
                    elRef.current = element;
                }, timeout ?? 0)
            }
        }
    }, [idPrefix, timeout, scrolledRef, hashRef, elRef, elParentRef]);
    
    
    
    return {scrollTo}
}