import { InstanceType } from "../../../interfaces";

export enum WorkContextType {
	WorkContext = "WorkContext",
	JobWorkConext = "JobWorkContext"
}

export enum PortalRole {
	None = 'None',
	BasicUser = 'BasicUser',
	Standard = 'Standard',
	ReviewerUser = 'ReviewerUser',
	PowerUser = 'PowerUser',
	Administrator = 'Administrator',
	CPUser = 'CPUser',
	CPAdministrator = 'CPAdministrator',
	SuperAdministrator = 'SuperAdministrator',
	SuperUser = 'SuperUser',
	AuditorCPUser = 'AuditorCPUser',
	ExternalAccess = 'ExternalAccess',
}

type JobWorkContextInfo = {
	id: number;
	guid: string;
	isDispatched: boolean;
}

type TenantWorkContextInfo = { 
	scopedTenant?: { 
		// ...
		// add mode props if required
		// ...
		type: InstanceType
	}
}

type IdentityUser = {
	id: string;
	name?: string;
	email?: string;
	roles: PortalRole[];
	
	employeeId: number;
	position: string;
	role?: string;
	teamId: number;
	
	isSuperUser: boolean;
	isSuperAdministrator: boolean;
	isPublisher: boolean;
	
	isCrmUser: boolean;
}

type UserWorkContextInfo = {
	isAuthorized: boolean;
	identityUser?: IdentityUser;
	maxRole: PortalRole;
}

export interface IWorkContext {
	type: WorkContextType;
	
	job?: JobWorkContextInfo;

	isContraventionTabEnabled: boolean;
    isAllTabsEnabled: boolean;

	isCurrentUserJobAuditor: boolean;
	isCurrentUserJobManager: boolean;
	isCurrentUserJobPartner: boolean;
	
	user?: UserWorkContextInfo;
	tenant?: TenantWorkContextInfo;
}