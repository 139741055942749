import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {IInvoice, IInvoiceExtraAmount, InvoiceJob, useInvoicesContext} from '../interfaces';
import {IDataTableProps} from '../../../interfaces';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IColumn,
    Icon,
    IconButton,
    IContextualMenuItem,
    Link,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import {CardTableContainerStyles} from '../../../../../constants/tableStylesPeset';
import {Card, DataTable, Modal, useContextMenu} from '../../../../../components';
import {FormatNumberOptions, FormattedMessage, useIntl} from 'react-intl';
import {BillingEntityType} from '../../../enums';
import {InvoiceStatus} from './InvoiceStatus';
import {InvoicesSearchPanel} from './InvoicesSearchPanel';
import {formatDate, formatIdNumber} from '../../../../../utils';
import {InvoiceExtraAmountDataTable} from './InvoiceExtraAmountDataTable';
import {useBoolean} from '@fluentui/react-hooks';
import {InvoiceParagraphType, InvoiceStatusType} from '../enums';
import {useInvoicePortalContext} from '../../../InvoicePortalLayoutPage';
import {useCreateCreditNote, useDeleteInvoice, useGenerateInvoice, useImportPayments} from '../hooks';
import {InvoiceAddForm} from './InvoiceAddForm';
import {Selection} from '@fluentui/react/lib/DetailsList';
import {InvoiceAddMultipleForm} from './InvoiceAddMultipleForm';
import {isMacOS} from '../helpers';
import {useInvoicePortalNotifications} from '../../../hooks';
import {PortalRole, useWorkContext} from '../../../../../providers';
import {useGetAttachment} from "../../../../JobPortal/hooks";
import {useGetFile} from "../../../../../hooks";

interface IInvoicesDataTableProps extends IDataTableProps<IInvoice> {
}

export const InvoicesDataTable: FunctionComponent<IInvoicesDataTableProps> = ({
                                                                                  items,
                                                                                  isLoading
                                                                              }: IInvoicesDataTableProps) => {
    const {formatMessage, formatNumber} = useIntl();
    const theme = useTheme();

    const {clientIdentifier, clientId} = useInvoicePortalContext();
    const {filter, setFilter, selection} = useInvoicesContext();
    const {setContextMenuItems: setMenuItems, closeMenu} = useContextMenu();
    const {isInRoleOrSuperAdministrator} = useWorkContext();
    const {showInfoNotification, showWarningNotification} = useInvoicePortalNotifications();

    const {deleteInvoice, isDeleting} = useDeleteInvoice();
    const {importPayments, isImporting} = useImportPayments();
    const {getAttachment, isLoading: isGettingAttachment} = useGetAttachment();
    const {getFile, isExportDownloading} = useGetFile();
    const {generateInvoice, isGenerating} = useGenerateInvoice();
    const {create: createCreditNote, isCreating: isCreditNoteCreating} = useCreateCreditNote(clientIdentifier);

    const [selectedStatus, setSelectedStatus] = useState<InvoiceStatusType>(filter.status);

    const [addModal, {toggle: toggleAddModal}] = useBoolean(false);
    const [addItem, setAddItem] = useState<IInvoice | undefined>();

    const [deleteDialog, {toggle: toggleDeleteDialog}] = useBoolean(false);
    const [deleteItemId, setDeleteItemId] = useState<number | undefined>();

    const [tableSelection, setTableSelection] = useState<Selection>(
        new Selection({
            onSelectionChanged: () => {
                setSelectedItems((prev) => {
                    const current = tableSelection.getSelection() as IInvoice[];
                    const toAdd: IInvoice[] = current.filter((i) => !prev.items.some((pi) => pi.hash === i.hash));
                    const toRemove: IInvoice[] = prev.items.filter((i) => !current.some((ci) => ci.hash === i.hash));
                    return {items: current, added: toAdd, removed: toRemove};
                });
            },
            getKey: (invoice: any, index?: number): string | number => (invoice as IInvoice).hash,
        })
    );

    const [selectedItems, setSelectedItems] = useState<{ items: IInvoice[]; added: IInvoice[]; removed: IInvoice[] }>({
        items: [],
        added: [],
        removed: [],
    });

    const [contextMenuItems, setContextMenuItems] = useState<IContextualMenuItem[]>([]);

    const reverseToCreditNoteContextMenuItem = useMemo(
        () => ({
            key: 'reverseToCreditNote',
            text: formatMessage({id: 'reverseToCreditNote'}),
            iconProps: {iconName: 'RevToggleKey'},
            onItemClick: (e: MouseEvent, item: any) => reverseToCreditNoteClick(item),
        }),
        [formatMessage]
    );

    const newInvoiceContextMenuItem = useMemo(
        () => ({
            key: 'newInvoice',
            text: formatMessage({id: 'newInvoice'}),
            iconProps: {iconName: 'Add'},
            onItemClick: (e: MouseEvent, item: any) => newInvoiceCLick(item),
        }),
        [formatMessage]
    );

    useEffect(() => {
        if (isLoading && !selectedItems.items.length) return;

        selection.setItems((prev) => {
            const newSelection: IInvoice[] = [...prev.filter((pi) => !selectedItems.removed.some((ri) => ri.hash === pi.hash))];
            newSelection.push(...selectedItems.added.filter((si) => !selection.items.some((i) => i.hash === si.hash)));
            return [...newSelection];
        });
    }, [selectedItems.items]);

    useEffect(() => {
        setSelectedStatus(filter.status);
    }, [filter.status]);

    useEffect(() => {
        if (!selection.items.length) {
            tableSelection.getSelection().forEach((s) => tableSelection.setKeySelected((s as IInvoice).hash, false, false));
        }
    }, [selection.items]);

    useEffect(() => {
        selection.items.forEach((i) => tableSelection.setKeySelected(i.hash, true, false));
    }, [items]);

    useEffect(() => {
        setMenuItems(contextMenuItems);
    }, [setMenuItems, contextMenuItems.length]);

    useEffect(() => {
        if ([InvoiceStatusType.Open, InvoiceStatusType.Closed, InvoiceStatusType.CreatedOnly].includes(selectedStatus)) {
            setContextMenuItems([reverseToCreditNoteContextMenuItem, newInvoiceContextMenuItem]);
        } else {
            setContextMenuItems([reverseToCreditNoteContextMenuItem]);
        }
    }, [selectedStatus]);

    const tableItems = useMemo<IInvoice[]>(() => {
        const result: IInvoice[] = [];
        (items ?? []).forEach((i: IInvoice) => {
            if (i.isComposite) {
                i.invoiceJobs.forEach((ij: InvoiceJob, idx: number) => {
                    result.push({
                        ...i,
                        invoiceJobs: [i.invoiceJobs[idx]],
                    });
                });
            } else {
                result.push(i);
            }
        });

        return result;
    }, [items]);

    const reverseToCreditNoteClick = (item: IInvoice) => {
        if (!item.isCreated) {
            showWarningNotification({id: 'reverseToCreditNote_notCreatedInvoiceError', values: {name: ''}});
            return;
        }

        createCreditNote({
            clientId: clientId,
            amount: item.total,
            creationDate: new Date(item.invoiceDate),
            billingEntityType: item.isComposite ? BillingEntityType.Client : BillingEntityType.Fund,
            billingEntityId: item.isComposite ? item.clientId : item.invoiceJobs[0].fundId,
        });
    };

    const newInvoiceCLick = (item: IInvoice) => {
        closeMenu();

        if (item.invoiceJobs[0].totalInvoiceCount >= 2) {
            showWarningNotification({id: 'invoice_jobLimit{value}ReachedMessage', values: {name: '', value: 2}});
            return;
        }

        onAddClick(item);
    };

    const onDeleteClick = (item: IInvoice) => {
        setDeleteItemId(item.id);
        toggleDeleteDialog();
    };

    const onAddClick = (item: IInvoice) => {
        setAddItem(item);
        toggleAddModal();
    };

    const onDownloadClick = (item: IInvoice) => {
        if (!!item.id) {
            getAttachment(item.entryId || 0, {
                onSuccess: (resp) => {
                    getFile({
                        url: String(resp.data?.downloadUrl),
                        fileName: resp.data.fileName,
                    });
                }
            });
        }
    };

    const onAddSubmit = (item: IInvoice) => {
        toggleAddModal();
        setAddItem(undefined);
    };

    const onImportPaymentsClick = () => {
        importPayments();
    };

    const isMultipleMode = useMemo<boolean>(
        () => selectedStatus === InvoiceStatusType.NotCreated || selectedStatus === InvoiceStatusType.NotCreatedDraftArSent,
        [selectedStatus]
    );

    useEffect(() => {
        if (!isMultipleMode) {
            selection.clear();
        }
    }, [isMultipleMode]);

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'invoiceNumber',
                name: formatMessage({id: 'invoiceNo'}),
                fieldName: 'id',
                minWidth: 95,
                maxWidth: 95,
                onRender: (item: IInvoice, idx) => (
                    <Text variant='medium' styles={{root: {fontWeight: 500}}}>
                        {item.isComposite ? '*' : ''}
                        {formatIdNumber(item.id || 0)}
                    </Text>
                ),
                isMultiline: true,
            },
            {
                key: 'jobName',
                name: formatMessage({id: 'job'}),
                fieldName: 'jobName',
                minWidth: 100,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: IInvoice) => <Text>{item.invoiceJobs[0]?.jobName}</Text>,
            },
            {
                key: 'fundName',
                name: formatMessage({id: 'fund'}),
                fieldName: 'fundName',
                minWidth: 190,
                maxWidth: 500,
                isMultiline: true,
                onRender: (item: IInvoice) => <Text>{item.invoiceJobs[0]?.fundName}</Text>,
            },
            {
                key: 'amount',
                name: formatMessage({id: 'amount'}),
                fieldName: 'amount',
                minWidth: 80,
                maxWidth: 100,
                isMultiline: true,
                onRender: (item: IInvoice) => (
                    <Text>
                        {formatNumber((item.isComposite ? item.invoiceJobs[0].amount : item.amount) ?? 0, {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Text>
                ),
            },
            {
                key: 'extraAmount',
                name: formatMessage({id: 'extraAmount'}),
                minWidth: 115,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: IInvoice) => (
                    <InvoiceExtraAmountColumn
                        items={
                            item.isComposite
                                ? [{amount: item.invoiceJobs[0].extraAmount} as IInvoiceExtraAmount]
                                : item.extraAmounts ?? []
                        }
                        isComposite={item.isComposite}
                    />
                ),
            },
            {
                key: 'total',
                name: formatMessage({id: 'total'}),
                fieldName: 'total',
                minWidth: 55,
                maxWidth: 100,
                isMultiline: true,
                onRender: (item: IInvoice) => (
                    <Text>
                        {formatNumber(
                            item.isComposite ? (item.invoiceJobs[0].amount ?? 0) + (item.invoiceJobs[0].extraAmount ?? 0) : item.totalAmount ?? 0,
                            {style: 'currency', currency: 'USD'}
                        )}
                    </Text>
                ),
            },
            {
                key: 'status',
                name: formatMessage({id: 'status'}),
                fieldName: 'status',
                minWidth: 110,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: IInvoice) => <InvoiceStatus status={item.status}/>,
            },
            {
                key: 'creationDate',
                name: formatMessage({id: 'creationDate'}),
                fieldName: 'creationDate',
                minWidth: 115,
                maxWidth: 135,
                isMultiline: true,
                onRender: (item: IInvoice) => (
                    <Text>
                        {item.status !== InvoiceStatusType.NotCreated && item.invoiceDate ? formatDate(item.invoiceDate, 'dd/MM/yyyy') : ''}
                    </Text>
                ),
            },
            {
                key: 'paragraph',
                name: formatMessage({id: 'invoiceParagraph'}),
                fieldName: 'paragraph',
                minWidth: 115,
                maxWidth: 135,
                isMultiline: true,
                onRender: (item: IInvoice) => <Text>{InvoiceParagraphType[item.paragraph]}</Text>,
            },
            {
                key: 'billingEntityType',
                name: formatMessage({id: 'billingEntity'}),
                fieldName: 'billingEntityType',
                minWidth: 85,
                maxWidth: 135,
                isMultiline: true,
                onRender: (item: IInvoice) => <Text>{BillingEntityType[item.billingEntityType]}</Text>,
            },
            {
                key: 'actions',
                name: '',
                fieldName: 'actions',
                minWidth: 52,
                maxWidth: 52,
                onRender: (item: IInvoice) => (
                    <Stack horizontal>
                        {!item.isCreated && (
                            <Stack.Item>
                                <IconButton
                                    iconProps={{iconName: 'Add'}}
                                    onClick={() => {
                                        onAddClick(item);
                                    }}
                                />
                            </Stack.Item>
                        )}
                        {item.isCreated && (
                            <Stack.Item>
                                <IconButton
                                    iconProps={{iconName: 'Installation'}}
                                    disabled={item.status === InvoiceStatusType.NotCreated}
                                    onClick={() => {
                                        onDownloadClick(item);
                                    }}
                                />
                            </Stack.Item>
                        )}
                        {item.isCreated && isInRoleOrSuperAdministrator() && (
                            <Stack.Item>
                                <IconButton
                                    iconProps={{iconName: 'Delete'}}
                                    styles={{
                                        icon: {color: theme.palette.red},
                                        iconHovered: {color: theme.palette.redDark}
                                    }}
                                    disabled={item.status === InvoiceStatusType.Closed}
                                    onClick={() => {
                                        onDeleteClick(item);
                                    }}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                ),
            },
        ],
        []
    );

    const classNames = mergeStyleSets({
        dataTable: {
            '.ms-DetailsRow-cell': {
                'justify-content': 'center',
            },
        },
        blackText: {
            color: theme.palette.blackTranslucent40,
        },
    });

    return (
        <>
            <Stack tokens={{childrenGap: 16}}>
                <Stack.Item>
                    <InvoicesSearchPanel></InvoicesSearchPanel>
                </Stack.Item>

                <Stack.Item>
                    <Card styles={CardTableContainerStyles}>
                        <Stack tokens={{childrenGap: 16}}>
                            <Stack.Item>
                                <Stack horizontal horizontalAlign='space-between' verticalAlign='center'
                                       tokens={{childrenGap: 16}}>
                                    <Stack.Item>
                                        {isMultipleMode && (
                                            <Stack
                                                className={classNames.blackText}
                                                horizontal
                                                horizontalAlign='start'
                                                verticalAlign='center'
                                                tokens={{childrenGap: 8}}>
                                                <Stack.Item>
                                                    <Icon iconName={'Info'}></Icon>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Text className={classNames.blackText}>
                                                        <FormattedMessage
                                                            id={'multipleInvoiceSelectionMessage{os}'}
                                                            values={{os: isMacOS() ? 'Cmd' : 'Ctrl'}}
                                                        />
                                                    </Text>
                                                </Stack.Item>
                                            </Stack>
                                        )}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal horizontalAlign='end' verticalAlign='center'
                                               tokens={{childrenGap: 16}}>
                                            {isMultipleMode && <CreateMultipleButton/>}
                                            <Stack.Item>
                                                <PrimaryButton onClick={onImportPaymentsClick}>
                                                    {isImporting ? (
                                                        <Spinner size={SpinnerSize.small}></Spinner>
                                                    ) : (
                                                        formatMessage({id: 'importPayments'})
                                                    )}
                                                </PrimaryButton>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DataTable
                                    className={classNames.dataTable}
                                    initialColumns={columns}
                                    enableShimmer={isLoading}
                                    shimmerLines={filter.pageSize}
                                    items={tableItems ?? []}
                                    selectionMode={isMultipleMode ? SelectionMode.multiple : SelectionMode.none}
                                    selection={tableSelection}
                                    hideIfEmpty
                                    emptyProps={{
                                        iconName: 'ComplianceAudit',
                                    }}
                                    setKey='none'
                                    isHeaderVisible
                                    containerHeight='calc(100% - 32px)'
                                    disableDragDrop={true}
                                />
                            </Stack.Item>
                        </Stack>
                    </Card>
                </Stack.Item>
            </Stack>

            <Dialog
                hidden={!deleteDialog || !deleteItemId}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: formatMessage({id: 'warning'}),
                    closeButtonAriaLabel: formatMessage({id: 'close'}),
                    subText: formatMessage({id: 'deleteInvoice'}),
                    theme: theme.schemes?.default,
                }}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            deleteInvoice(
                                {clinetIdentifier: clientIdentifier, id: deleteItemId!},
                                {onSettled: () => setDeleteItemId(undefined)}
                            );
                            toggleDeleteDialog();
                        }}
                        text={formatMessage({id: 'yes'})}
                    />
                    <DefaultButton
                        onClick={() => {
                            setDeleteItemId(undefined);
                            toggleDeleteDialog();
                        }}
                        text={formatMessage({id: 'no'})}
                    />
                </DialogFooter>
            </Dialog>

            {addItem && (
                <Modal
                    isOpen={addModal}
                    title={`${formatMessage({id: 'addInvoice'})} - ${addItem.invoiceJobs[0].jobName} - ${
                        addItem.invoiceJobs[0].fundName
                    }`}
                    onDismiss={toggleAddModal}>
                    <InvoiceAddForm item={addItem} onClose={toggleAddModal} onSubmit={onAddSubmit}></InvoiceAddForm>
                </Modal>
            )}
        </>
    );
};

type CreateMultipleButtonProps = {};

const CreateMultipleButton: FunctionComponent<CreateMultipleButtonProps> = ({}: CreateMultipleButtonProps) => {
    const {formatMessage} = useIntl();

    const {selection} = useInvoicesContext();

    const [addMultipleModal, {toggle: toggleAddMultipleModal}] = useBoolean(false);

    const onClick = (items: IInvoice[]): void => {
        toggleAddMultipleModal();
    };

    const onClear = (): void => {
        selection.clear();
    };

    const onAddMultipleSubmit = (item: IInvoice) => {
        toggleAddMultipleModal();
        selection.clear();
    };

    return (
        <>
            <Stack.Item>
                <Stack horizontal horizontalAlign={'end'} verticalAlign='center' tokens={{childrenGap: 16}}>
                    <Stack.Item>
                        <FormattedMessage id={'{count}selected'} values={{count: selection.items.length}}/>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{iconName: 'Clear'}} onClick={onClear}
                                    disabled={!selection.items.length}/>
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton
                            disabled={!selection.items.length}
                            onClick={() => onClick(selection.items)}
                            text={formatMessage({id: 'createInvoiceForMultipleJobs'})}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            {!!selection.items.length && (
                <Modal isOpen={addMultipleModal} title={formatMessage({id: 'addInvoice'})}
                       onDismiss={toggleAddMultipleModal}>
                    <InvoiceAddMultipleForm
                        items={selection.items}
                        onClose={toggleAddMultipleModal}
                        onSubmit={onAddMultipleSubmit}></InvoiceAddMultipleForm>
                </Modal>
            )}
        </>
    );
};

interface IInvoiceExtraAmountColumnProps {
    items: IInvoiceExtraAmount[];
    isComposite: boolean;
}

const InvoiceExtraAmountColumn: FunctionComponent<IInvoiceExtraAmountColumnProps> = ({
                                                                                         items,
                                                                                         isComposite,
                                                                                     }: IInvoiceExtraAmountColumnProps) => {
    const {formatMessage, formatNumber} = useIntl();
    const _numberFormatOptions: FormatNumberOptions = {style: 'currency', currency: 'USD'};

    const [modalOpen, {toggle: toggleModalOpen}] = useBoolean(false);

    return (
        <>
            <Stack>
                <Stack.Item>
                    {(items ?? []).length ? (
                        isComposite ? (
                            <Text>{formatNumber(items[0].amount, _numberFormatOptions)}</Text>
                        ) : (
                            <Link onClick={toggleModalOpen}>
                                {formatNumber(
                                    items.reduce((prev: number, curr: IInvoiceExtraAmount) => prev + +curr.amount, 0),
                                    _numberFormatOptions
                                )}
                            </Link>
                        )
                    ) : (
                        <Text>{formatNumber(0, _numberFormatOptions)}</Text>
                    )}
                </Stack.Item>
            </Stack>

            <Modal title={formatMessage({id: 'extraAmount'})} isOpen={modalOpen} onDismiss={toggleModalOpen}>
                <Card>
                    <InvoiceExtraAmountDataTable items={items}/>
                </Card>
            </Modal>
        </>
    );
};
